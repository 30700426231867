<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-events="true">
      <ion-grid fixed>
        <ion-row class="ion-justify-content-center">
          <ion-img
            style="height: 85px;width: 170px;"
            src="assets/icon/AAP_Logo_original_para-mail.png"
          ></ion-img>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-align-items-center">
          <ion-text>Gestión de campañas agrícolas</ion-text>
        </ion-row>
        <ion-row
          class="ion-justify-content-center ion-align-items-center"
          style="margin-top: 24px"
        >
          <ion-col class="ion-align-self-center" size-md="6" size="12">
            <ion-card class="ion-align-self-start">
              <ion-card-header>
                <ion-card-title size="4">Crear Cuenta</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                <ion-button
                  expand="block"
                  text-color="white"
                  strong
                  color="googlebutton"
                  @click="auth('google')"
                  v-if="false"
                >
                  <!-- v-if="!disableEmail" -->
                  GOOGLE
                </ion-button>
                <form @submit.prevent="authLocal">
                  <ion-item>
                    <ion-label position="floating">Email</ion-label>
                    <ion-input
                      :disabled="disableEmail"
                      v-model.lazy="form.email"
                      inputmode="email"
                      type="email"
                      autocomplete="email"
                      placeholder="juanperez@hotmail.com"
                    ></ion-input>
                  </ion-item>
                  <ion-item v-if="!disableEmail">
                    <ion-label position="floating">
                      Contraseña
                    </ion-label>
                    <ion-input
                      v-model.lazy="form.password"
                      :type="showPass ? 'text' : 'password'"
                      placeholder="********"
                    ></ion-input>
                    <ion-button
                      fill="clear"
                      slot="end"
                      size="large"
                      @click="showPass = !showPass"
                    >
                      <ion-icon
                        class="showPass ion-margin-bottom"
                        color="dark"
                        :icon="
                          showPass ? icons.eyeOffOutline : icons.eyeOutline
                        "
                      ></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item v-if="!disableEmail">
                    <ion-label position="floating">
                      Confirmar contraseña
                    </ion-label>
                    <ion-input
                      v-model.lazy="password2"
                      :type="showPass2 ? 'text' : 'password'"
                      placeholder="********"
                    ></ion-input>
                    <ion-button
                      fill="clear"
                      slot="end"
                      size="large"
                      @click="showPass2 = !showPass2"
                    >
                      <ion-icon
                        class="showPass ion-margin-bottom"
                        color="dark"
                        :icon="
                          showPass2 ? icons.eyeOffOutline : icons.eyeOutline
                        "
                      ></ion-icon>
                    </ion-button>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">
                      Nombre
                    </ion-label>
                    <ion-input
                      :disabled="disableEmail"
                      v-model.lazy="form.nombre"
                      autocapitalize="words"
                      type="text"
                      inputmode="text"
                      autocomplete="name"
                      placeholder="Juan"
                    ></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating">Apellido</ion-label>
                    <ion-input
                      :disabled="disableEmail"
                      v-model.lazy="form.apellido"
                      autocapitalize="words"
                      type="text"
                      inputmode="text"
                      autocomplete="family-name"
                      placeholder="Perez"
                    ></ion-input>
                  </ion-item>
                  <br />
                  <ion-item :disabled="disableEmail && disableProvincia">
                    <ion-label position="floating">Provincia</ion-label>
                    <ion-select
                      :value="form.provincia"
                      @ionChange="form.provincia = $event.target.value"
                    >
                      <ion-select-option
                        v-for="p in provincias"
                        :value="p"
                        :key="p"
                      >
                        {{ p }}
                      </ion-select-option>
                    </ion-select>
                  </ion-item>
                  <div>
                    <ion-item
                      v-if="!displaySearchbar"
                      :disabled="disableLocalidad"
                      @click="switchDisplaySearchbar()"
                    >
                      <ion-label position="floating">Localidad</ion-label>
                      <ion-input
                        :value="capitalizar(form.localidad.nombre)"
                        autocapitalize="words"
                        type="text"
                        inputmode="text"
                        readonly="true"
                      ></ion-input>
                    </ion-item>
                    <ion-row v-if="displaySearchbar">
                      <ion-col style="margin-left: 4px">
                        <ion-label>
                          Localidad
                        </ion-label>
                      </ion-col>
                    </ion-row>
                    <ion-searchbar
                      placeholder="Localidad"
                      @ionChange="filtroLocalidad = $event.target.value"
                      :value="capitalizar(form.localidad.nombre)"
                      v-if="displaySearchbar"
                    />
                    <div
                      v-if="displaySearchbar"
                      style="margin-left: 56px; margin-right: 8px;"
                    >
                      <ion-list
                        lines="full"
                        v-for="l in localidades"
                        :value="l"
                        :key="l._id"
                      >
                        <ion-item
                          class="reduceLateralMargin"
                          button="true"
                          type="button"
                          @click="selectLocalidad(l)"
                        >
                          <ion-label
                            style="white-space: normal; font-size: 12px !important"
                          >
                            {{ capitalizar(l.nombre) }}
                          </ion-label>
                        </ion-item>
                      </ion-list>
                    </div>
                  </div>
                  <div
                    v-if="
                      displaySearchbar && localidades && localidades.length > 0
                    "
                    style="background-color: rgba(var(--ion-color-medium-rgb), 0.375); 
                    padding-top: 4px; margin-left: 56px; margin-right: 8px;"
                  ></div>
                  <ion-item>
                    <ion-label position="floating">
                      Regional
                    </ion-label>
                    <ion-label position="floating" style="font-size: 12px">
                      (Opcional)
                    </ion-label>
                    <ion-select
                      :value="form.regional"
                      @ionChange="form.regional = $event.target.value"
                    >
                      <ion-select-option
                        v-for="reg in regionales"
                        :value="reg._id"
                        :key="reg._id"
                      >
                        {{ reg.nombre }}
                      </ion-select-option>
                    </ion-select>
                  </ion-item>
                  <ion-item v-show="error">
                    <ion-text color="danger">
                      <h4>{{ error }}</h4>
                    </ion-text>
                  </ion-item>
                  <ion-button
                    fill="outline"
                    class="login-btn"
                    color="medium"
                    @click="$router.push('/login')"
                  >
                    <ion-text style="font-size:small">
                      Cancelar
                    </ion-text>
                  </ion-button>
                  <ion-button
                    slot="end"
                    class="ion-float-right login-btn"
                    type="submit"
                    :disabled="crearCuentaBtnDisabled"
                  >
                    Crear Cuenta
                  </ion-button>
                </form>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>
<script>
import { mapActions } from "vuex";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";
import { Regionales, Provincias } from "@/api";
import capitalizeWords from "@/utils/capitalizeWords";
import http from "@/api/http";
import Token from "@/api/Token";
import {
  IonPage,
  IonGrid,
  IonContent,
  IonList,
  IonSearchbar,
  IonRow,
  IonCol,
  alertController,
  IonCard,
  IonCardContent,
  IonButton,
  IonItem,
  IonImg,
  IonInput,
  IonSelect,
  IonText,
  IonSelectOption,
  IonLabel,
  IonIcon,
  IonCardHeader,
  IonCardTitle
} from "@ionic/vue";

export default {
  components: {
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonSearchbar,
    IonContent,
    IonCard,
    IonCardContent,
    IonImg,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonItem,
    IonInput,
    IonLabel,
    IonText,
    IonIcon,
    IonCardHeader,
    IonCardTitle
  },
  data: () => ({
    icons: {
      eyeOutline,
      eyeOffOutline
    },
    showPass: false,
    showPass2: false,
    password2: "",
    form: {
      nombre: "",
      apellido: "",
      email: "",
      password: "",
      provincia: "",
      localidad: "",
      regional: ""
    },
    disableEmail: false,
    disableProvincia: false,
    filtroLocalidad: "",
    crearCuentaBtnDisabled: true,
    regionales: [],
    localidades: [],
    provincias: [],
    error: "",
    displaySearchbar: false,
    disableLocalidad: true
  }),

  async mounted() {
    await this.dataSync();
  },

  computed: {},

  created() {
    const token = this.$route.query.token;
    if (token) Token.set(token);
    else if (!Token.get()) this.loading = false;
    else this.loading = false;
  },

  watch: {
    "form.provincia": {
      handler: function(val) {
        if (val) {
          this.disableLocalidad = false;
        }
        this.form.localidad = "";
      },
      deep: true,
      immediate: true
    },
    "form.localidad": {
      handler: function() {
        this.displaySearchbar = false;
        this.filtroLocalidad = "";
      },
      deep: true,
      immediate: true
    },
    async filtroLocalidad(val) {
      if (val !== "" && val.length >= 3) {
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(async () => {
          await this.getLocalidades();
        }, 500);
      } else {
        this.localidades = [];
      }
    }
  },

  methods: {
    ...mapActions(["signup", "getAapresidUser", "signupAapresid"]),
    async authLocal() {
      this.error = "";
      let errorValidacion = "";
      if (!this.form.email) {
        errorValidacion += "<li>Complete el campo <b>Email</b>." + `<br/></li>`;
      }
      if (!this.form.nombre) {
        errorValidacion +=
          "<li>Complete el campo <b>Denominación</b>." + `<br/></li>`;
      }
      if (!this.form.apellido) {
        errorValidacion +=
          "<li>Complete el campo <b>Apellido</b>." + `<br/></li>`;
      }
      if (!this.form.provincia) {
        errorValidacion += "<li>Elija una <b>Provincia</b>." + `<br/></li>`;
      }
      if (!this.disableEmail) {
        if (!this.form.password) {
          errorValidacion +=
            "<li>Complete el campo <b>Contraseña</b>." + `<br/></li>`;
        }
        if (!this.password2) {
          errorValidacion +=
            "<li>Complete el campo <b>Confirmar Contraseña</b>." + `<br/></li>`;
        }
        if (this.form.password !== this.password2) {
          errorValidacion +=
            "<li>Las <b>Contraseñas</b> ingresadas no son iguales." +
            `<br/></li>`;
        }
      }
      if (!errorValidacion) {
        const { error, status } = await this.signup({
          user: this.form,
          wPassword: !this.disableEmail
        });
        if (error && status === 409) {
          this.error =
            "Error: El email ya está registrado como usuario del Sistema.";
        } else {
          if (!this.disableEmail) {
            const alert = await alertController.create({
              header: "Activación de Cuenta",
              message:
                "Hemos enviado un email a su dirección de correo electrónico para activar su cuenta.",
              buttons: [
                {
                  text: "Aceptar",
                  handler: () => {
                    this.$router.push("/login");
                  }
                }
              ]
            });
            return alert.present();
          }
          this.$router.push({
            name: "login",
            query: {
              tkn: this.$route.query.tkn
            }
          });
        }
      } else {
        const alert = await alertController.create({
          header: "Error",
          message: errorValidacion,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
    },

    switchDisplaySearchbar() {
      this.displaySearchbar = !this.displaySearchbar;
    },

    capitalizar(str) {
      return capitalizeWords(str);
    },

    async dataSync() {
      this.crearCuentaBtnDisabled = true;
      const token = this.$route.query.tkn;
      if (token) {
        const { data, error } = await this.getAapresidUser(token);
        if (!error) {
          this.crearCuentaBtnDisabled = false;
          await this.getRegionales();
          await this.getProvincias();
          this.disableEmail = true;
          this.form.nombre = data.nombre;
          this.form.apellido = data.apellido;
          this.form.email = data.email;
          if (
            data.provincia &&
            this.provincias.some(
              p => p.toUpperCase() === data.provincia.toString().toUpperCase()
            )
          ) {
            this.disableProvincia = true;
            this.form.provincia = data.provincia;
            this.form.localidad = await this.getLocalidadUserAapresid(
              data.localidad
            );
          } else {
            this.disableProvincia = false;
          }
        } else {
          window.location.replace("/login");
        }
      } else {
        window.location.replace("/login");
      }
    },

    async getRegionales() {
      const { data, error } = await Regionales.get({ sort: { nombre: 1 } });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo regionales.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      this.regionales = data;
    },

    async getLocalidadUserAapresid(localidad) {
      const { data, error } = await http.get(
        "/api/get-localidades-por-nombre-provincia",
        {
          params: {
            provincia: this.form.provincia.toString(),
            filtroLocalidad: localidad.toString().toLowerCase()
          }
        }
      );
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo la localidad.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      if (data.length === 1) {
        this.disableLocalidad = true;
        return data[0];
      }
      this.disableLocalidad = false;
      return "";
    },

    async getLocalidades() {
      const { data, error } = await http.get(
        "/api/get-localidades-por-nombre-provincia",
        {
          params: {
            provincia: this.form.provincia.toString(),
            filtroLocalidad: this.filtroLocalidad.toString().toLowerCase()
          }
        }
      );
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo localidades.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      this.localidades = data;
    },

    async getProvincias() {
      const { data, error } = await Provincias.get({ sort: { nombre: 1 } });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo provincias.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      this.provincias = data.map(p => capitalizeWords(p.nombre));
    },

    selectLocalidad(loc) {
      this.form.localidad = loc;
      this.filtroLocalidad = "";
    },

    auth(type) {
      this.loading = true;
      const url = process.env.VUE_APP_SERVER_API + "/api/persona_auth/" + type;
      window.location.replace(url);
    }
  }
};
</script>
<style scoped>
ion-list {
  background-color: rgba(var(--ion-color-medium-rgb), 0.375);
  padding-top: 4px;
  padding-bottom: 0px;
}

.reduceLateralMargin {
  margin-left: 4px;
  margin-right: 4px;
}

.mt-150 {
  margin-top: 150px;
}
.showPass {
  position: relative;
  top: 50%;
  transform: translateY(-25%);
}

.login-btn {
  margin-top: 25px;
}

@media only screen and (min-width: 1024px) {
  #headerAAG {
    margin-left: 296px;
    margin-right: 296px;
    margin-top: 32px;
  }
  #tittle {
    margin-left: 104px;
  }
}
</style>
